// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-jsx": () => import("./../../../src/templates/404.jsx" /* webpackChunkName: "component---src-templates-404-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-information-jsx": () => import("./../../../src/templates/Information.jsx" /* webpackChunkName: "component---src-templates-information-jsx" */),
  "component---src-templates-preview-jsx": () => import("./../../../src/templates/Preview.jsx" /* webpackChunkName: "component---src-templates-preview-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/Project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-projects-list-jsx": () => import("./../../../src/templates/ProjectsList.jsx" /* webpackChunkName: "component---src-templates-projects-list-jsx" */)
}

